<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card max-width="600" grid-list-lg>
      <ModelTitle title="New Vendor" @close="close()" :color="$style.purchase.titlebar"/>

      <v-container grid-list-lg>
        <v-layout row wrap>
          <v-flex xs12>
            <v-text-field
              outlined
              :hide-details="nameErrors.length === 0"
              dense
              :error-messages="nameErrors"
              v-model="vendor.name"
              ref="vendorName"
              label="Name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6>
            <v-text-field
              outlined
              hide-details
              dense
              v-model="vendor.contactName"
              label="Contact Name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6>
            <v-text-field
              outlined
              dense
              hide-details
              v-model="vendor.phoneNumber"
              label="Phone Number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              outlined
              hide-details
              dense
              v-model="vendor.email"
              label="Email Address"
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              outlined
              dense
              hide-details
              v-model="vendor.address"
              label="Address"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 sm6>
            <v-text-field
              outlined
              hide-details
              dense
              v-model="vendor.city"
              label="City"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm3>
            <v-text-field
              outlined
              hide-details
              dense
              v-model="vendor.state"
              label="State"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm3>
            <v-text-field
              hide-details
              outlined
              dense
              v-model="vendor.zip"
              label="Zip Code"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-textarea
              outlined
              hide-details
              dense
              auto-grow
              v-model="vendor.description"
              :label="$t('labels.description')"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close()">Close</v-btn>
        <v-btn color="blue darken-1" :loading="loading" dark @click="submit()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import vendorService from "../service.js";

const { validationMixin } = require("vuelidate");
const { required } = require("vuelidate/lib/validators");

export default {
  name: "create-vendor",
  props: {
    createDialog: {
      default: false
    },
    createFromStore: {
      type: Boolean
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      vendor: {
        name: null,
        email: null,
        phoneNumber: null,
        contactName: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        note: null
      }
    };
  },
  mixins: [validationMixin],
  validations: {
    vendor: {
      name: {
        required
      }
    }
  },
  watch: {
    createDialog(val) {
      this.dialog = val;
      this.$nextTick(() => {
        this.$refs.vendorName.focus();
      });
    }
  },
  computed: {
    ...mapGetters("global", ["currentUser"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.vendor.name.$dirty) return errors;
      if (!this.$v.vendor.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));

      return errors;
    }
  },
  methods: {
    close() {
      this.empty();
      this.$emit("close");
    },
    empty() {
      this.vendor = {
        name: null,
        email: null,
        phoneNumber: null,
        contactName: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        note: null
      };
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields")
          });
      } else {
        this.loading = true;
        return vendorService
          .create(this.vendor)
          .then(result => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000
                })
                .fire({
                  icon: "success",
                  title: "vendor is created"
                });
              this.loading = false;
              this.$emit("submit");
              this.close();
            }
          })
          .catch(err => {
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000
              })
              .fire({
                icon: "error",
                title: err.data.message
              });
          });
      }
    }
  }
};
</script>

<style scoped></style>
