<template>
  <v-container fluid grid-list-lg>
    <!-- <Create
      :createDialog="createDialog"
      @close="createDialog = false"
      @submit="getList"
    ></Create>
    <Edit
      :editDialog="editDialog"
      @close="editDialog = false"
      @submit="getList"
      :editVendor="selectedvendor"
    ></Edit> -->
    <v-card :loading="loading" outlined>
      <ListTitle :showBackButton="true" :access="false" title="Serial" :color="$style.purchase.titlebar">
        <!-- <RemoteOrderLink :globalLink="true" :button="true" /> -->
        <v-btn outlined rounded small @click="changeStatusDialog = true">Change Status</v-btn>
      </ListTitle>

      <v-toolbar dense elevation="0">

        <v-text-field v-model="search" outlined prepend-inner-icon="search" autofocus dense label="Search" single-line
          hide-details clearable></v-text-field>

        <!-- <v-overflow-btn :items="dropdown_font" label="Select font" hide-details class="pa-0"></v-overflow-btn> -->

        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-divider vertical class="mx-1"></v-divider>



          <v-overflow-btn :items="statusList" @change="getList()" return-object item-text="name" clearable editable
            outlined dense label="Department" v-model="selectedStatus" hide-details class="pa-0"
            overflow></v-overflow-btn>

          

          <v-spacer></v-spacer>

        


        </template>
        <DateTimePicker
              :defaultRange="currentBusiness.metadata.invoiceViewLimit ? currentBusiness.metadata.invoiceViewLimit.range : 'since15DaysAgo'"
              :range="true" :hideTime="true" v-model="dateTime" @submit="getList()" />
      </v-toolbar>
      <v-divider></v-divider>

      <!-- <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" single-line
            hide-details clearable></v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
        <v-layout column class="text-right">
          <v-flex shrink>
            <v-chip-group v-model="status" mandatory active-class="primary--text">
              <v-chip> Available </v-chip>
              <v-chip> Sold </v-chip>
              <v-chip> On Hold </v-chip>
              <v-chip> Repair </v-chip>
            </v-chip-group>
          </v-flex>
          <v-flex>
            <DateTimePicker
              :defaultRange="currentBusiness.metadata.invoiceViewLimit ? currentBusiness.metadata.invoiceViewLimit.range : 'since15DaysAgo'"
              :range="true" :hideTime="true" v-model="dateTime" @submit="getList()" />
          </v-flex>
        </v-layout>
      </v-card-title> -->

      <v-card-text>
        <v-data-table :headers="headers" :items="vendors" :search="search" :loading="loading"
          :server-items-length="count" :options.sync="options">
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <td class="text-left">
                {{ item.createdAt | moment("MM/DD/YYYY") }}
              </td>
              <td class="text-left" v-html="$highlight(
                item.Product.name,
                search
              )
                ">
                {{ item.Product.name }}
              </td>
              <td>
                <v-layout row wrap>
                  <v-flex shrink>
                    <v-btn @click="setOpenSerialSearch(item)" text icon small color="primary">
                      <v-icon small>mdi-feature-search-outline</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex shrink>
                    <v-btn text icon color="primary" @click="copyCode(item.imeiNumber)" small>
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>

              </td>
              <td class="text-left" v-html="$highlight(
                item.imeiNumber,
                search
              )
                ">
                {{ item.imeiNumber }}
              </td>

              <td class="text-left" v-html="$highlight(textCapitalize(item.status), search)
                ">
                {{ textCapitalize(item.status) }}
              </td>
              <td class="text-right">
                {{ item.cost | currency }}
              </td>

              <!-- <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
    <v-list class="menu-button">
      <v-list-item v-if="checkRightStatus(19)" @click="viewProfile(item)">
        <v-list-item-title>Profile</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item v-if="checkRightStatus(19)" @click="edit(item)">
        <v-list-item-title>Edit</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="checkRightStatus(11)" @click="deletevendor(item)">
        <v-list-item-title>Delete</v-list-item-title>
      </v-list-item>
    </v-list>
    </v-menu>
    </td> -->
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no
            results.</v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
    <ChangeStatus @close="changeStatusDialog = false" @submit="getList()" :changeStatusDialog="changeStatusDialog" />
  </v-container>
</template>

<script>
import serialService from "@/modules/Inventory/Serial/service.js";
import { mapGetters, mapActions } from "vuex";
const RemoteOrderLink = () => import("@/components/RemoteOrderLink");
import ChangeStatus from '@/modules/Inventory/Serial/components/ChangeStatus.vue'
// const Create = () => import("./Create");
const DateTimePicker = () => import("@/components/DateTimePicker");
// const Edit = () => import("./Edit");
import _ from 'lodash';
export default {
  name: "vendor-list",
  data() {
    return {
      selectedStatus: {
        id: 'available',
        name: 'Available'
      },
      statusList: [
        {
          id: 'available',
          name: 'Available'
        },
        {
          id: 'sold',
          name: 'Sold'
        },
        {
          id: 'onHold',
          name: 'On Hold'
        },
        {
          id: 'repair',
          name: 'Repair'
        }
      ],
      status: 0,
      createDialog: false,
      dateTime: {
        start: this.$moment()
          .startOf("days")
          .subtract(60, "days")
          .toISOString(),
        end: this.$moment().endOf("day").toISOString(),
      },
      editDialog: false,
      selectedvendor: null,
      changeStatusDialog: false,
      headers: [
        {
          text: "Created At",
          align: "left",
          value: "createdAt",
          sortable: false,
        },
        {
          text: "Product Name",
          align: "left",
          value: "Product.name",
          sortable: false,
        },
        {
          text: "",
          value: "action",
          sortable: false,
          // width: "60",
        },
        {
          text: "IMEI Number",
          align: "left",
          value: "imeiNumber",
          sortable: false,
        },

        {
          text: "Status",
          align: "left",
          value: "status",
          sortable: true,
        },
        {
          text: "Cost",
          value: "cost",
          sortable: true,
        },
      ],
      vendors: [],
      count: 0,
      search: "",
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['balance']
      },
    };
  },
  created() {
    this.getList();
    document.title = "smplsale - Serial List";
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus", "currentBusiness"]),
    // selectedStatus() {
    //   if (this.status === 0) {
    //     return "available";
    //   } else if (this.status === 1) {
    //     return "sold";
    //   } else if (this.status === 2) {
    //     return "onHold";
    //   } else if (this.status === 3)
    //     return "repair";
    //   return null
    // },
  },
  components: {
    DateTimePicker,
    RemoteOrderLink,
    ChangeStatus
  },
  watch: {
    status() {
      this.getList();
    },
    search: _.debounce(function (val) {
      // this.resetProduct()
      if (this.search === null) {
        // this.search = "";
        this.getList();
      } else if (this.search.length > 2 || this.search.length === 0) {

        this.getList();

      }

    }, 300),
    options: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  // components: {
  //   Create,
  //   Edit,
  // },
  methods: {
    ...mapActions("layout", ["setOpenSerialSearch", "setOpenRestockSerial"]),
    copyCode(code) {
      navigator.clipboard.writeText(code);
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: true,
          timer: 2000,
        })
        .fire({
          icon: "success",
          title: "IMEI number was copy",
        });
      // this.$toast.success("Location code was copy", { timeout: 2000 });
    },
    textCapitalize(val) {
      const capitalizedText = val.charAt(0).toUpperCase() + val.slice(1);
      return capitalizedText
    },
    viewProfile(val) {
      this.$router.push({ path: `/inventory/vendor/${val.id}/profile` });
    },
    add() {
      this.createDialog = !this.createDialog;
    },
    getList() {
      this.loading = true;
      this.changeStatusDialog = false;
      const { page, itemsPerPage } = this.options;
      let offset = 0
      if (itemsPerPage > 0 && page) {
        offset = itemsPerPage * page - itemsPerPage;
      }
      let where = {
        status: this.selectedStatus.id,
        limit: itemsPerPage,
        start: this.dateTime.start,
        end: this.dateTime.end,
        offset: offset,
      }
      if (this.search && this.search.length > 2) {
        where.search = this.search
      } 
      // this.editDialog = false;
      // this.createDialog = false;
      return serialService
        .getAll(where)
        .then((response) => {
          // console.log('response', response)
          this.loading = false;
          this.count = response.count
          this.vendors = response.data;
          return response;
        });
    },
    edit(vendor) {
      // console.log("oldVendor", vendor);
      this.selectedvendor = vendor;
      this.editDialog = !this.editDialog;
    },
    deletevendor(vendor) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          serialService.delete(vendor.id).then((response) => {
            if (response.data) {
              this.$swal("Deleted!", "vendor has been deleted.", "success");
              this.getList();
            }
          });
        }
      });
    },
  },
};
</script>
