<template>
  <v-dialog persistent v-model="dialog" width="500">
    <v-card>
      <ModelTitle title="Change Status" @close="close" />
      <v-container grid-list-md>
        <v-layout row align-center>
          <v-flex xs12>
            <v-select :disabled="serialList.length ? true : false" dense outlined :items="items"
              v-model="selectedStatus" label="Select Status" item-text="name" return-object hide-details></v-select>
          </v-flex>
        </v-layout>
        <v-divider class="my-2"></v-divider>
        <v-layout row align-center>
          <v-flex>
            <v-text-field outlined label="Serial Number" v-model="serialNumber" @keyup.enter="enterSerial"
              ref="enterSerial" @focus="$event.target.select()" :disabled="loading" dense
              :loading="loading"></v-text-field>
          </v-flex>
          <v-flex shrink align-self-start="">
            <v-btn :disabled="!selectedStatus || !serialNumber" :loading="loading" large color="info"
              @click="enterSerial"> add </v-btn>
          </v-flex>
        </v-layout>
        <v-list class="list-350">
          <template v-for="(row, index) in serialList">
            <v-list-item :key="index">
              <v-list-item-avatar>
                <v-btn icon>
                  <v-icon color="red" @click="deleteNumber(index)">delete</v-icon>
                </v-btn>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="row">
                  <span>{{ row }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" v-if="serialList.length" @click="clearAll">Clear All</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" :loading="serialDialogLoading" large :disabled="!serialList.length" text
          @click="updateSerials">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import serialService from "@/modules/Inventory/Serial/service.js";

export default {
  name: 'change-status',
  props: ['changeStatusDialog'],
  data() {
    return {
      serialNumber: null,
      selectedStatus: null,
      items: ['available', 'onHold', 'repair'],
      serialDialogLoading: false,
      dialog: false,
      serialList: [],
      loading: false,
    }
  },
  watch: {
    changeStatusDialog(val) {
      this.dialog = val
    },
  },
  methods: {
    enterSerial() {
      this.loading = true
      if (this.serialList.length) {
        let index = this.serialList.indexOf(this.serialNumber);
        if (index != -1) {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
            })
            .fire({
              icon: "error",
              title: `${this.serialNumber} is already in this list`,
            });
          this.loading = false

          return true
        }
      }
      return serialService.getAll({ imeiNumber: this.serialNumber }).then((response) => {
        this.loading = false
        if (!response.data.length) {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
            })
            .fire({
              icon: "error",
              title: `${this.serialNumber} not found`,
            });
          return true
        } else if (response.data[0].status === 'sold') {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
            })
            .fire({
              icon: "error",
              title: `${this.serialNumber} is sold. You can't change the status of sold serial number`,
            });
          return true
        } else if (response.data[0].status === this.selectedStatus) {
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
            })
            .fire({
              icon: "error",
              title: `${this.serialNumber} status is already ${this.selectedStatus}`,
            });
          return true
        }
        this.serialList.push(this.serialNumber)
        this.serialNumber = null
        this.$nextTick(() => {
          this.$refs.enterSerial.focus();
        });
        return response;
      });
    },
    deleteNumber(index) {
      this.serialList.splice(index, 1)
    },
    clearAll() {
      this.serialList = []
    },
    updateSerials() {
      let obj = {
        serialList: this.serialList,
        status: this.selectedStatus,
      }
      this.serialDialogLoading = true
      return serialService
        .updateAllBySerialNumber(obj)
        .then(result => {
          if (result.status) {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: true,
                timer: 5000
              })
              .fire({
                icon: "success",
                title: 'Serials Updated'
              });
            this.serialDialogLoading = false;
            this.$emit('submit')
            this.close()
          }
        })
        .catch(err => {
          this.serialDialogLoading = false;
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000
            })
            .fire({
              icon: "error",
              title: err.data.message
            });
        });
    },
    close() {
      this.serialNumber = null,
        this.selectedStatus = null,
        this.serialList = [],
        this.$emit('close')
    }
  }
}
</script>