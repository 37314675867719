<template>
  <router-view></router-view>
</template>

<script>
import RegisterStoreModule from "@/mixins/RegisterStoreModule";
import vendorStore from "./store.js";

export default {
  name: 'vendor',
   mixins: [RegisterStoreModule],
  created() {
    this.registerStoreModule("vendor", vendorStore);
  }
};
</script>
