<template>
  <v-container v-if="getVendor" class="pa-0" grid-list-lg fluid>
    <v-alert
      v-if="getVendor"
      border="left"
      type="error"
      tile
      transition="slide-y-transition"
      dismissible
      class="mb-2"
      v-model="getVendor.alert"
      color="red"
    >
      <strong>{{ getVendor.note }}</strong>
    </v-alert>
    <Edit
      v-if="getVendor"
      :editDialog="editDialog"
      @close="editDialog = false"
      @submit="loadData"
      :editVendor="getVendor"
    ></Edit>
    <v-card tile v-if="getVendor">
      <v-toolbar dense flat :color="$style.purchase.titlebar">
        <v-app-bar-nav-icon @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title class="text-capitalize">
          {{ getVendor.name }}
        </v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list class="menu-button">
            <div v-if="checkRightStatus(5)">
              <v-list-item
                v-if="$vuetify.breakpoint.xsOnly"
                @click="newPurchase"
              >
                <v-list-item-title>New Purchase</v-list-item-title>
              </v-list-item>
            </div>
            <v-list-item v-if="checkRightStatus(19)" @click="editDialog = true">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>            
            <DeleteVendor :vendor="getVendor" @submit="$router.go(-1)" />
            <SyncBalance />
            <Statement :VendorId="getVendor.id" />
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout
          class="mt-5"
          v-if="!getVendor"
          align-center
          justify-center
          column
          fill-height
        >
          <v-progress-circular
            size="70"
            width="7"
            color="purple"
            indeterminate="indeterminate"
          ></v-progress-circular>
          <div>Loading...</div>
        </v-layout>
        <v-layout row wrap v-else justify-space-between>
          <v-flex shrink>
            <div class="text-capitalize">
              <span class="font-weight-medium">Contact Name: </span>
              <span v-if="getVendor.contactName">{{
                getVendor.contactName
              }}</span>
              <span v-else>Not Set</span>
            </div>
            <div class="text-capitalize">
              <span class="font-weight-medium">Phone Number: </span>
              <span v-if="getVendor.phoneNumber">{{
                getVendor.phoneNumber | USPhoneNumber
              }}</span>
              <span v-else>Not Set</span>
            </div>
            <div class="text-capitalize">
              <span class="font-weight-medium">Cell Number: </span>
              <span v-if="getVendor.cellNumber">{{
                getVendor.cellNumber | USPhoneNumber
              }}</span>
              <span v-else>Not Set</span>
            </div>
            <div>
              <span class="font-weight-medium">Email: </span>
              <a v-if="getVendor.email" @click="sendEmail(getVendor.email)">{{
                getVendor.email
              }}</a>
              <span v-else>Not Set</span>
            </div>

            <span v-if="!$vuetify.breakpoint.xsOnly">
              <v-btn
                class="mt-2"
                color="info"
                v-if="checkRightStatus(6)"
                @click="newPurchase"
                >New Purchase</v-btn
              >
            </span>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex shrink>
            <div class="text-right">
              <v-tooltip bottom v-if="getVendor.address">
                <template v-slot:activator="{ on }">
                  <a v-on="on" @click="openGoogleMap">
                    <div class="text-capitalize title">
                      {{ getVendor.address }}
                    </div>
                    <div class="text-capitalize">
                      {{ getVendor.city }}, {{ getVendor.state }}
                      {{ getVendor.zip }}
                    </div>
                  </a>
                </template>
                <span>Click to open google map</span>
              </v-tooltip>

              <div class="mt-1">
                <span class="font-weight-medium">Vendor Since: </span>
                <span>{{ getVendor.createdAt | moment("from", "now") }}</span>
              </div>

              <div class="mt-3" v-if="checkRightStatus(33)">
                <div class="text-capitalize title">Balance</div>
                <div
                  :style="{ color: balanceColor }"
                  class="text-capitalize font-weight-light display-3"
                >
                  {{ getVendor.balance | currency }}
                </div>
                <div>Limit: {{ getVendor.balanceLimit | currency }}</div>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <v-tabs v-model="active" color="black" show-arrows grow>
      <v-tab>{{ $t("labels.dashboard") }}</v-tab>
      <v-tab v-if="checkRightStatus(48)">Purchase Order</v-tab>
      <v-tab>Open Invoice</v-tab>
      <v-tab>Payments</v-tab>
      <v-tab>All Invoice</v-tab>
      <v-tab>Attachment</v-tab>
    </v-tabs>
    <v-tabs-items
      background-color="transparent"
      v-model="active"
      touchless="touchless"
    >
      <v-tab-item>
        <Dashboard> </Dashboard>
      </v-tab-item>
      <v-tab-item v-if="checkRightStatus(48)">
        <PurchaseOrder v-if="getVendor" :VendorId="getVendor.id"> </PurchaseOrder>
      </v-tab-item>
      <v-tab-item>
        <PurchaseList
          :due="true"
          :showAll="true"
          v-if="getVendor"
          :VendorId="getVendor.id"
        >
        </PurchaseList>
      </v-tab-item>
      <v-tab-item>
        <PaymentList v-if="getVendor" :VendorId="getVendor.id"> </PaymentList>
      </v-tab-item>
      <v-tab-item>
        <PurchaseList v-if="getVendor" :VendorId="getVendor.id"> </PurchaseList>
      </v-tab-item>
      <v-tab-item>
        <AttachmentList v-if="getVendor" TypeId="12" :RelationId="getVendor.id">
        </AttachmentList>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
const Dashboard = () => import("@/modules/Inventory/Vendor/components/Dashboard");
const PurchaseList = () => import("@/modules/Inventory/Purchase/components/List");
const AttachmentList = () => import("@/modules/Attachment/components/List");
const PurchaseOrder = () => import("@/modules/Inventory/PurchaseOrder/components/List");
const PaymentList = () => import("@/modules/Inventory/Payment/components/List");
const DeleteVendor = () => import("@/modules/Inventory/Vendor/components/DeleteVendor");
const Statement = () => import("@/modules/Inventory/Vendor/components/Statement");
const SyncBalance = () => import("@/modules/Inventory/Vendor/components/syncBalance.vue")
import { mapGetters, mapActions } from "vuex";

import Hashids from "hashids";
const hashids = new Hashids();

const Edit = () => import("@/modules/Inventory/Vendor/components/Edit");

export default {
  name: "Vendor-profile",
  components: {
    Edit,
    Dashboard,
    SyncBalance,
    Statement,
    PurchaseList,
    AttachmentList,
    PaymentList,
    PurchaseOrder,
    DeleteVendor,
  },
  data() {
    return {
      fab: false,
      active: null,
      editDialog: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to && from && to.name === from.name) {
        this.loadData();
      }
    },
  },
  created() {
    this.loadData();
    this.$events.listen("VendorProfileEvent", () => this.loadData());
  },
  beforeDestroy() {
    this.$events.remove("VendorProfileEvent");
  },
  computed: {
    ...mapGetters("vendor", ["getVendor"]),
    ...mapGetters("global", ["checkRightStatus"]),
    balanceColor() {
      let color = "black";
      if (this.getVendor.balance > this.getVendor.balanceLimit) {
        color = "red";
      } else {
        color = "green";
      }

      return color;
    },
  },
  methods: {
    ...mapActions("vendor", ["setVendor"]),
    ...mapActions("global", ["setPageTitle"]),
    viewOpenInvoice() {
      const hashId = hashids.encode(this.getVendor.id);
      const routeData = this.$router.resolve({
        path: `/global/invoice/${hashId}/openInvoice`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    newPurchase() {
      this.$router.push({
        path: `/inventory/purchase/create?VendorId=${this.getVendor.id}`,
      });
    },
    async loadData() {
      const id = this.$route.params.id;
      this.$Progress.start();
      await this.setVendor(id);
      // this.setPageTitle(this.getVendor.name);
      this.$Progress.finish();
    },
    openGoogleMap() {
      window.open(
        `https://www.google.com/maps/place/${this.getVendor.address} ${this.getVendor.city},${this.getVendor.state} ${this.getVendor.zip}`,
        "_blank"
      );
    },
    sendEmail() {
      let routeData =
        "https://mail.google.com/mail/?view=cm&fs=1&to=" + this.getVendor.email;
      window.open(routeData, "newwindow", "width=800, height=950");
    },
    closeEdit() {
      this.editDialog = false;
    },
    updated() {
      this.closeEdit();
      this.loadData();
    },
  },
};
</script>
