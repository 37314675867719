import vendoerService from './service'

export default {
  namespaced: true,
  state: {
    Vendor: null,
  },
  getters: {
    getVendor(state) {
      return state.Vendor
    },
  },
  actions: {
    setVendor({
      commit
    }, id) {
      return vendoerService.getById(id)
        .then(response => {
          if (!response.data.alert) response.data.alert = false

          commit('SET_VENDOER', response.data)
        })

    },
  },
  mutations: {
    SET_VENDOER(state, payload) {
      // console.log('payload from vendor', payload)
      state.Vendor = payload
      document.title = `smplsale - ${payload.name}`
    },
  },
}